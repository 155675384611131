











import * as Sentry from '@sentry/browser'
import { Component } from 'vue-property-decorator'
import { GroupModel } from '@technology/accounts-js/dist/types/modules/group'
import { OnActive } from 'vue-plugin-helper-decorator'
import Vue from 'vue'

import Snackbar from '~/components/common/Snackbar.vue'
import config from '~/config'
import util from '~/util'

@Component({
  components: {
    Snackbar,
  },
})
export default class App extends Vue {
  public validateSuccess: boolean = false
  public signInPath: string = config.signInPath
  // computed

  public async onResize() {
    this.$store.myActions.setIsMobile(window.innerWidth < config.mobileViewWidthBreakPoint)
  }

  public async beforeMount(): Promise<void> {
    try {
      // Check if it is authenticated user
      const result = await this.$store.myActions['accounts/group/fetchList']({
        type: '',
        q: '',
        viewType: 'user',
      })
      const groups = util.distinct([
        ...result.data,
        ...util.objectValues(result.nested.groups),
      ]) as GroupModel[]

      const account = await this.$store.myActions['accounts/account/fetchAccount']()
      // fetch group require accounts
      await Promise.all([
        groups.map(group => {
          return this.$store.myActions['accounts/group/fetch'](group.id)
        }),
      ])
      this.validateSuccess = true

      // set sentry user
      if (account && account.id) {
        Sentry.configureScope(scope => {
          scope.setUser({
            displayName: account.displayName,
            email: account.email,
            googleId: account.googleId,
            id: account.id,
            isDeleted: account.isDeleted,
            isSuspended: account.isSuspended,
            type: account.type,
          })
        })
      }
    } catch (err) {
      console.log(err)
      if (err.status) {
        if (err.status === 401) {
          window.location.href = config.signInPath
        } else if (err.status === 404) {
          // User service is down
          window.location.href = '/'
        }
      }
    }
  }

  @OnActive()
  public async checkIsLogged() {
    try {
      await this.$store.myActions['accounts/account/fetchAccount']()
    } catch (err) {
      console.log(err)
      if (err.status) {
        if (err.status === 401) {
          window.location.href = config.signInPath
        } else if (err.status === 404) {
          // User service is down
          window.location.href = '/'
        }
      }
    }
  }
}
